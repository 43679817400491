import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

interface Props {
  className?: string
  children: React.ReactNode
}

const isOverflown = ({ clientHeight = null, scrollHeight = null }) => {
  return scrollHeight > clientHeight
}

export const EloModalBody: React.FC<Props> = ({ children, className }) => {
  const containerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>
  const [isOverflow, setIsOverflow] = useState(false)

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setIsOverflow(isOverflown(containerRef.current || {}))
    })

    resizeObserver.observe(containerRef.current)

    return () => {
      containerRef.current && resizeObserver.unobserve(containerRef.current)
    }
  }, [containerRef.current])

  return (
    <div className={classNames('elo-ui-modal__body', className, { overflow: isOverflow })} ref={containerRef}>
      {children}
    </div>
  )
}
