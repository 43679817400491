import React from 'react'
import classNames from 'classnames'

import { EloButton } from '@elo-ui/components/elo-button'
import { EloWarningCircleIcon } from '@elo-ui/components/icons/regular'
import { EloInfoMessage } from '@elo-ui/components/elo-info-message'
import { patchLink } from 'utils/link.utils'

import './elo-card.scss'

interface Props {
  type?: 'selectable' | 'display' | 'informational' | 'disabled'
  children: React.ReactNode
  optionalComponent?: React.ReactNode
  buttonIcons?: {
    icon: React.ReactNode
    onClick?: () => void
  }[]
  alignment?: 'space-between' | 'packed'
  displayUrl?: string
  contentClassName?: string
  selected?: boolean
  handleSelect?: () => void
  handleDisplayClick?: () => void
  elevation?: '1' | '2'
  className?: string
  selectedBorderColor?: string
  footerClassName?: string
  infoMessageStatus?: 'success' | 'warning' | 'error' | 'information'
  infoMessageIcon?: React.ReactNode
  infoMessageLocale?: string
}

export const EloCard: React.FC<Props> = ({
  type = 'selectable',
  children,
  optionalComponent,
  buttonIcons = [],
  alignment = 'space-between',
  displayUrl = '',
  contentClassName = '',
  selected = false,
  handleSelect,
  handleDisplayClick = () => {},
  elevation = '2',
  className,
  footerClassName,
  selectedBorderColor,
  infoMessageStatus = 'warning',
  infoMessageIcon = <EloWarningCircleIcon />,
  infoMessageLocale,
}) => {
  const cardClasses = classNames(
    'elo-card',
    `elo-card__${type}`,
    {
      'elo-card__selectable--selected': selected,
    },
    `elo-card--elevation-${elevation}`,
    className
  )
  const contentClasses = classNames('elo-card__content', contentClassName)
  const cardFooterClasses = classNames('elo-card__footer', `elo-card__footer--${alignment}`, footerClassName)

  const handleCardClick = () => {
    switch (type) {
      case 'selectable': {
        handleSelect && handleSelect()
        break
      }
      case 'display': {
        if (displayUrl) {
          window.open(patchLink(displayUrl), '_blank')
        } else {
          handleDisplayClick()
        }
        break
      }
      default: {
        return null
      }
    }
  }

  const isDisabled = type === 'disabled'

  return (
    <div
      className={cardClasses}
      onClick={handleCardClick}
      style={
        {
          '--elo-card-selected-border-color': selectedBorderColor,
        } as React.CSSProperties
      }
    >
      <div className={contentClasses}>{children}</div>
      <div className={cardFooterClasses}>
        <div className='elo-card__footer--optional'>{optionalComponent && optionalComponent}</div>
        <div className='elo-card__footer--buttons'>
          {buttonIcons?.map((item, index) => (
            <EloButton
              key={index}
              variant='ghost'
              onClick={(e) => {
                e.stopPropagation()
                item.onClick()
              }}
              size='medium'
              leftIcon={item.icon}
            />
          ))}
        </div>
      </div>

      {isDisabled && infoMessageLocale && (
        <div className='elo-card__info-message'>
          <EloInfoMessage status={infoMessageStatus} icon={infoMessageIcon}>
            {infoMessageLocale}
          </EloInfoMessage>
        </div>
      )}
    </div>
  )
}
